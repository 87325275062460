import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/artful.module.css'
import Layout from '../../components/Layout'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c6" }) {
      headline
      byline
      aerial {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      sign {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      market {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      bwmarket {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      redyellow {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const ArtfulCommunity = () => {
  const { content } = useStaticQuery(essayQuery)
  return (
    <Layout>
      <section>
        <h1 className='headline'>{content.headline}</h1>
          <h2>About Seaside</h2>
            <p>
              My future husband, Robert Davis, introduced me to the wilderness
              of NW Florida&apos;s coast, on the Gulf of Mexico, to an area
              called Walton County. This was a large part of Florida I was
              unaware of and had always assumed belonged to the state of
              Alabama. The constituents of this area were a different sort who
              considered themselves to be urban dropouts. The reality was that
              their southern families preferred them to live in beach houses
              tucked away from society where they could carry on their &quot;not
              so proper&quot; business in private. These folks were renegades —
              very happy to live off the beaten path. They rarely socialized
              with outsiders. The only meeting places in the area were churches,
              a country club and a dog track. There wasn&apos;t one proper
              gathering place over 17 miles of the County Road 30A, the main
              thoroughfare.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.bwmarket.childImageSharp]}
              />
              Seaside Saturday Market, c. 1981
            </div>
            <div>
              <p>
                In the summer of 1980 I decided to leave Miami and move up to
                the wildness of the Florida Panhandle, to a city called Grayton
                Beach near some land my husband&apos;s family had left to him. We
                were going to begin Seaside.
              </p>
              <p>
                Robert began networking with different builders and carpenters
                and I devoted myself to finding a job in community counseling.
                Community counseling was my postgraduate major and before we
                moved up to what would become Seaside I was working in the
                field. I was surprised to discover that there weren&apos;t any
                community services available in Walton County. Along the coast
                most of the locals self-medicated and took care of their
                problems without professional help.
              </p>
              <p>
                I found myself with enormous amounts of time on my hands. To
                explore the culture I gravitated towards the three towns near
                Grayton Beach: Panama City, Fort Walton Beach and Pensacola. The
                area lacked an urban environment. The mall was the closest
                example of urbanism I could find and it was a sufficient enough
                to observe the lifestyles of folks who lived in these areas.
              </p>
            </div>
            <p>
              My future husband, Robert Davis, introduced me to the wilderness
              of NW Florida&apos;s coast, on the Gulf of Mexico, to an area
              called Walton County. This was a large part of Florida I was
              unaware of and had always assumed belonged to the state of
              Alabama. The constituents of this area were a different sort who
              considered themselves to be urban dropouts. The reality was that
              their southern families preferred them to live in beach houses
              tucked away from society where they could carry on their &quot;not
              so proper&quot; business in private. These folks were renegades —
              very happy to live off the beaten path. They rarely socialized
              with outsiders. The only meeting places in the area were churches,
              a country club and a dog track. There wasn&apos;t one proper
              gathering place over 17 miles of the County Road 30A, the main
              thoroughfare.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.redyellow.childImageSharp]}
              />
              The &quot;Yellow House&quot; and &quot;Red House&quot; c. 1982
            </div>
            <p>
              Around that time I began experimenting with selling cotton items,
              dresses, sun caps, and colorful webbed army belts that I picked up
              at the local Salvation Army store. Cotton clothing and items in
              fun beach colors were not to be found in this 90 mile stretch of
              beach along the Gulf of Mexico. I brought these items up to
              Seaside from south Florida. I had purchased them at discount
              stores, used clothing shops, army surplus shops, etc. My eye was
              highly developed to find quality merchandise that I would be able
              to resell at my market. I knew nothing of mark-ups and sales tax
              but I was very much interested in the psychology of pricing. I
              began selling these items with my local produce including
              strawberries, southern peaches and Dothan tomatoes. The cotton
              items took off. I was unable to find enough to resell while my
              produce sales were flat. On Sundays, Robert and I started cooking
              up the leftovers from the stand into tomato sauce, strawberry jam,
              banana bread and whatever else remained. What we didn&apos;t anticipate
              was the effect of these aromas on house sales. We had visitors
              coming through our sales model, the Yellow House we lived in,
              while cooking up these tempting treats. This sense of
              &quot;home&quot; helped to show future home buyers the advantages
              of the cottages we were proposing.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.market.childImageSharp]}
              />
              Seaside Saturday Market c. 1982
            </div>
            <p>
              At the same time, I began to create activities with the intent of
              drawing people to Seaside. We sponsored volleyball on the beach at
              sunset, sailboat regattas from Seaside to Grayton Beach and back,
              storytelling, sand castle building contests, watermelon spitting
              contests, dancing under the stars, piano recitals on the bluff
              overlooking the Gulf at sunset, and outdoor movies. A friend from
              Coconut Grove, Florida had passed on to me a 16- millimeter
              projector that used huge reels. I order the movies and learned to
              be the projectionist — playing the films on a handmade screen.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.sign.childImageSharp]}
              />
              Seaside Sign, c. 1983
            </div>
            <p>
              I signed up to attend the Panama City Vocational School to study
              drafting. Robert and I created the first Seaside tee shirt by
              using a letterpress. I purchased the Hanes men and boy tees from
              the basement of Pizitz, Robert&apos;s family department store in
              Birmingham, Alabama. The next step was to find a screen printer to
              print Seaside on those tees. We did that, learned the printing
              business, and we were on our way with a dozen shirts. The tees
              were sold for $10 each alongside my tomatoes and peaches. The
              first dozen tees sold out quickly. Seaside tees provided an
              opportunity for people to be part of something bigger than
              themselves and yet small enough to be considered cool and
              cult-like.
            </p>
            <p>
              By 1982, Seaside was growing as a town and a destination, and we
              were having weekly events. I was running back and forth to Curry
              Copy Center in Panama City creating newspaper ads for these events
              and the Seaside Saturday Market. We promoted these events through
              the local newspapers and by word of mouth. Seaside Tees were
              continuing to sell through the roof, my cotton clothes were flying
              off the veggie table, and customers were walking through our model
              home on a regular basis. We had the beginnings of a destination.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.aerial.childImageSharp]}
              />
              Aerial View of Retail Development, c. 1983
            </div>
            <p>
              I can&apos;t say we planned for this to happen, and I can&apos;t
              say that we didn&apos;t. We both were very interested in creating
              activities in Seaside and from those first attempts at community
              building our retail enterprise slowly began and flourished. Our
              Saturday Market turned into stores and restaurants and our events
              became major attractions. Seaside became and remains a major
              destination in the Florida Panhandle for shopping, dining,
              goings-on, and vacations. And it all began with some ingenuity,
              the dream of a better way of life, and a little veggie stand…
            </p>
      </section>
    </Layout>
  )
}

export default ArtfulCommunity
